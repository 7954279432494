import { Controller } from "@hotwired/stimulus";
import { switchPriceType } from "../util";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll(".price-type").forEach((radio) => {
      radio.addEventListener("change", (e) => switchPriceType(e.target.value));
    });
    switchPriceType(this.element.querySelector(".price-type:checked").value);
  }
}
