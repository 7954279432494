import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["annotationSelect"];

  connect() {
    // ref: https://qiita.com/Kyo18/items/cac00086d272ddb1fe01
    $(this.annotationSelectTarget).on("select2:select", function () {
      const event = new Event("change", { bubbles: true });
      this.dispatchEvent(event);
    });
  }

  template() {
    const annotationId = document.getElementById(
      "contract_annotation_annotation_id"
    ).value;

    fetch(`/annotations/${annotationId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        // テンプレートを内容入力欄にセット
        const contentElement = document.getElementById(
          "contract_annotation_content"
        );
        contentElement.value = data.template;
        M.textareaAutoResize(contentElement);
        contentElement.focus();
      })
      .catch((error) =>
        console.error("Error fetching annotation template:", error)
      );
  }
}
