import { Controller } from '@hotwired/stimulus';
import { switchPriceType } from '../util';

export default class extends Controller {
	static targets = ['productSelect'];

	connect() {
		// ref: https://qiita.com/Kyo18/items/cac00086d272ddb1fe01
		$(this.productSelectTarget).on('select2:select', function () {
			const event = new Event('change', { bubbles: true });
			this.dispatchEvent(event);
		});
	}

	template() {
		const productId = document.getElementById('contract_item_product_id').value;

		fetch(`/products/${productId}`, {
			method: 'GET',
		})
			.then((response) => response.json())
			.then((data) => {
				// 数量
				const quantityElement = document.getElementById(
					'contract_item_quantity'
				);
				// 月額利用料の場合は契約期間の月数を設定
				if (data.unit === 'ヶ月') {
					const contractMonths =
						document.getElementById('contract_months').value;
					quantityElement.value = contractMonths;
				} else {
					quantityElement.value = data.quantity;
				}
				quantityElement.focus();
				// 単位
				const unitElement = document.getElementById('contract_item_unit');
				unitElement.value = data.unit;
				unitElement.focus();
				// 固定金額
				const fixedAmountElement = document.getElementById(
					'contract_item_fixed_amount'
				);
				fixedAmountElement.value = data.fixed_amount;
				fixedAmountElement.focus();
				// 単価タイプ
				const priceTypeInput = document.querySelectorAll(
					'input[type="radio"].price-type'
				);
				priceTypeInput.forEach((input) => {
					if (input.value === data.price_type) {
						input.checked = true;
					}
				});

				switchPriceType(document.querySelector('.price-type:checked').value);

				if (data.price_type === 'single') {
					document.getElementById('contract_item_price').focus();
				} else {
					// 単価テーブル
					const priceStageElements =
						document.querySelectorAll('tr.price-stage');
					priceStageElements.forEach((priceStageElement) => {
						priceStageElement.classList.add('deleted');
						priceStageElement.querySelector(
							'.delete-price-stage-value'
						).value = true;
					});

					data.price_stages
						.sort((a, b) => a.from - b.from)
						.forEach((price_stage, idx) => {
							const trs = document.querySelectorAll('tbody.price-stages tr');
							const tr = trs[trs.length - 1].cloneNode(true);
							tr.classList.remove('deleted');
							tr.setAttribute('data-controller', 'price_stage');
							tr.querySelector('.delete-price-stage-value').value = false;
							document.querySelector('tbody.price-stages').appendChild(tr);

							// newIdが同じになる場合があるためidxを加算
							const newId = new Date().getTime() + idx;

							tr.querySelectorAll('input').forEach((input) => {
								input.value = '';
								input.name = input.name.replace(/\d+/, newId);
								input.id = input.id.replace(/\d+/, newId);
							});

							tr.querySelectorAll('td input')[0].value = price_stage.from;
							tr.querySelectorAll('td input')[1].value = price_stage.to;
							tr.querySelectorAll('td input')[2].value = price_stage.price;
						});

					document.querySelectorAll('tr.price-stage.deleted').forEach((tr) => {
						tr.style.display = 'none';
					});
				}
			})
			.catch((error) =>
				console.error('Error fetching product template:', error)
			);
	}
}
