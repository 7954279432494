import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.initializePriceStages();

    const addButton = this.element.querySelector(".add-price-stage");
    if (addButton) {
      addButton.addEventListener("click", this.addStage.bind(this));
    }

    const deleteButtons = this.element.querySelectorAll(".delete-price-stage");
    if (deleteButtons.length > 0) {
      deleteButtons.forEach((deleteButton) => {
        deleteButton.addEventListener("click", this.deleteStage.bind(this));
      });
    }
  }

  deleteStage(event) {
    event.preventDefault();
    const priceStages = document.querySelectorAll(
      "tr.price-stage:not(.deleted)"
    );
    if (priceStages.length === 1) {
      alert("最後の行は削除できません。");
      return;
    }

    if (confirm("行を削除します。よろしいですか？")) {
      const tr = event.target.closest("tr.price-stage");
      tr.querySelector(".delete-price-stage-value").value = true;
      tr.classList.add("deleted");
      tr.style.display = "none";
    }
  }

  addStage(event) {
    event.preventDefault();
    const tbody = this.element.querySelector("tbody.price-stages");
    const trElements = tbody.querySelectorAll("tr");
    const lastTr = trElements[trElements.length - 1];
    const newTr = lastTr.cloneNode(true);
    newTr.classList.remove("deleted");
    newTr.removeAttribute("style");
    const deleteButton = newTr.querySelector(".delete-price-stage");
    deleteButton.addEventListener("click", this.deleteStage.bind(this));
    tbody.appendChild(newTr);
    const newId = new Date().getTime();

    newTr.querySelectorAll("input").forEach((input) => {
      input.value = "";
      input.name = input.name.replace(/\d+/, newId);
      input.id = input.id.replace(/\d+/, newId);
    });
  }

  initializePriceStages() {
    this.element
      .querySelectorAll(".delete-price-stage-value")
      .forEach((input) => {
        if (input.value === "true") {
          const tr = input.closest("tr");
          tr.classList.add("deleted");
          tr.style.display = "none";
        }
      });
  }
}
