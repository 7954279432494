import M from "@materializecss/materialize";
import { Controller } from "@hotwired/stimulus";
import { initializeSelect2 } from "../util";

export default class extends Controller {
  connect() {
    initializeSelect2();

    this.modal = M.Modal.init(this.element);
    this.modal.open();
  }

  close(event) {
    if (event.detail.success) {
      this.modal.close();
      Turbo.visit(window.location.toString());
    }
  }
}
