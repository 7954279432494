import M from '@materializecss/materialize/dist/js/materialize.min';
import 'select2/dist/js/select2.min';

export function initializeSelect2() {
	var selectElm = document.querySelectorAll('select');
	M.FormSelect.init(selectElm);

	$('.select2').select2({ dropdownParent: $('.modal-content') });
}

export function initializeSelect2Multiple() {
	$('.select2').select2({
		multiple: true,
		closeOnSelect: false,
	});
}

export function switchPriceType(priceType) {
	if (priceType === 'single') {
		showSinglePrice();
	} else if (priceType === 'table') {
		showPriceTable();
	}
}

export function showSinglePrice() {
	showElement('.price-single');
	hideElement('.price-table');
}

export function showPriceTable() {
	showElement('.price-table');
	hideElement('.price-single');
}

export function showElement(selector) {
	const element = document.querySelector(selector);
	if (element) {
		element.style.display = 'block';
	}
}

export function hideElement(selector) {
	const element = document.querySelector(selector);
	if (element) {
		element.style.display = 'none';
	}
}
