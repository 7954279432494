import { Controller } from "@hotwired/stimulus";
import M from "@materializecss/materialize/dist/js/materialize.min";

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:load", this.load);
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.load);
  }

  initialize() {
    const flashMessage = document.querySelector("#flash-message");
    const notice = flashMessage.dataset.notice;
    const alert = flashMessage.dataset.alert;

    if (notice)
      M.toast({ text: notice, classes: "green", displayLength: 4000 });
    if (alert) M.toast({ text: alert, classes: "red", displayLength: 10000 });
  }
}
