import { Controller } from "@hotwired/stimulus";
import M from "@materializecss/materialize/dist/js/materialize.min";

export default class extends Controller {
  connect() {
    this.load = this.load.bind(this);
    document.addEventListener("turbo:load", this.load);
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.load);
  }

  initialize() {
    M.updateTextFields();
    const textarea = document.querySelector("textarea");
    if (textarea) M.textareaAutoResize(textarea);
  }

  load() {
    M.updateTextFields();
    const textarea = document.querySelector("textarea");
    if (textarea) M.textareaAutoResize(textarea);
  }
}
