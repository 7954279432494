import { Controller } from "@hotwired/stimulus";
import { initializeSelect2Multiple } from "../util";

export default class extends Controller {
  connect() {
    this.load = this.load.bind(this);
    document.addEventListener("turbo:load", this.load);
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.load);
  }

  initialize() {
    initializeSelect2Multiple();
  }

  load() {
    initializeSelect2Multiple();
  }
}
