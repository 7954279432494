import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import M from '@materializecss/materialize/dist/js/materialize.min';
import 'select2/dist/js/select2.min';

export default class extends Controller {
	connect() {
		document.addEventListener('turbo:load', this.load.bind(this));
	}

	disconnect() {
		document.removeEventListener('turbo:load', this.load.bind(this));
	}

	initialize() {
		this.initializeComponents();
	}

	load() {
		this.initializeComponents();
	}

	initializeComponents() {
		var selectElm = document.querySelectorAll('select');
		M.FormSelect.init(selectElm);

		$('.page-per-select select').on('change', this.changePerPage.bind(this));

		const datepickerOptions = {
			format: 'yyyy-mm-dd',
			autoClose: true,
			yearRange: 7,
			i18n: {
				months: [
					'1月',
					'2月',
					'3月',
					'4月',
					'5月',
					'6月',
					'7月',
					'8月',
					'9月',
					'10月',
					'11月',
					'12月',
				],
				monthsShort: [
					'1月',
					'2月',
					'3月',
					'4月',
					'5月',
					'6月',
					'7月',
					'8月',
					'9月',
					'10月',
					'11月',
					'12月',
				],
				weekdays: [
					'日曜日',
					'月曜日',
					'火曜日',
					'水曜日',
					'木曜日',
					'金曜日',
					'土曜日',
				],
				weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
				weekdaysAbbrev: ['日', '月', '火', '水', '木', '金', '土'],
				clear: 'クリア',
				cancel: '閉じる',
			},
		};
		this.initializeDatePicker(datepickerOptions);
		this.initializeMaxDateTodayDatePicker(datepickerOptions);
		this.initializeSelect2();
		this.initializeAutoSubmitSearch();
		this.initializeJpPostal();

		$('.select-dropdown').on('mousedown', (e) => {
			$(e.target).parent().click();
			e.preventDefault();
		});

		if ($.fn.select2.amd) {
			var select2 = $.fn.select2.amd.require('select2/results');
			select2.prototype.highlightFirstItem = function () {};
		}

		const target =
			'#q_client_company_name_or_client_yayoi_code_or_client_company_name_zenhan_cont';
		let afterTextInput = false;

		$(target).ready(function () {
			$(target).val('');
		});

		$(document).on('compositionend', '.select2-search', function () {
			afterTextInput = true;
			// Windowsの場合はkeyupイベントを発火させる
			if (navigator.platform.indexOf('Win') > -1) {
				$('.select2-search').trigger('keyup');
			}
		});

		$(document).on('keyup', '.select2-search', function (e) {
			if (e.key === 'Enter' && !afterTextInput) {
				e.preventDefault();
				$('#new_q').submit();
			}

			afterTextInput = false;
			// 一度絞り込み選択するとidの情報が残ってしまい、次に部分一致検索ができなくなる為valを削除
			$('#q_client_id_eq').val('');
			const input_words = $('.select2-search input').val();
			const hidden_field = $(
				'#q_client_company_name_or_client_yayoi_code_or_client_company_name_zenhan_cont'
			);
			hidden_field.val(input_words);
		});
	}

	changePerPage() {
		let queryParams;
		if ($.isEmptyObject(location.search)) {
			queryParams = '?per=' + $('#per').val();
		} else {
			queryParams = '?per=' + $('#per').val() + '&' + location.search.substr(1);
		}

		location.search = queryParams;
	}

	initializeDatePicker(options) {
		const elems = document.querySelectorAll('.date-picker');
		M.Datepicker.init(elems, options);
	}

	initializeMaxDateTodayDatePicker(options) {
		options['maxDate'] = new Date();
		const elems = document.querySelectorAll('.max-date-today-date-picker');
		M.Datepicker.init(elems, options);
	}

	initializeSelect2() {
		$('.select2').select2();
	}

	initializeAutoSubmitSearch() {
		$('.auto-submit-search-form input[type=checkbox]').on(
			'change',
			function () {
				$(this).closest('form').submit();
			}
		);

		$('.auto-submit-search-form input[type=radio]').on('change', function () {
			$('#input_words').val(null);
			$(this).closest('form').submit();
		});

		$('.auto-submit-search-form select').on('change', function () {
			$(this).closest('form').submit();
		});
	}

	initializeJpPostal() {
		$('.postal-code-field').jpostal({
			postcode: ['.postal-code-field'],
			address: { '.address-target': '%3%4%5%6%7' },
		});
	}
}
