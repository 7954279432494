import M from '@materializecss/materialize';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		document.addEventListener('turbo:load', this.load.bind(this));
	}

	disconnect() {
		document.removeEventListener('turbo:load', this.load.bind(this));
	}

	initialize() {
		this.initializeSidenav();
	}

	load() {
		this.initializeSidenav();
	}

	initializeSidenav() {
		const elems = document.querySelectorAll('.confirmation-sidenav');

		const tableRows = document.querySelectorAll('.contract-tr');

		const options = {
			edge: 'right',
			onOpenStart: function () {
				tableRows.forEach(function (row) {
					row.classList.remove('selected-row');
				});
			},
		};
		M.Sidenav.init(elems, options);

		// overlayのclickイベントを無効化
		M.Sidenav.prototype._createOverlay = function () {
			var overlay = document.createElement('div');
			overlay.classList.add('sidenav-overlay');

			document.body.appendChild(overlay);
			M.Sidenav.prototype._overlay = overlay;
		};

		const sidenavLinks = document.querySelectorAll('.sidenav-link');

		// 契約一覧画面での利用申込書確認用
		sidenavLinks.forEach(function (link) {
			link.addEventListener('click', function (e) {
				e.preventDefault();
				const targetId = this.getAttribute('data-target');
				const elm = document.getElementById(targetId);

				if (elm === null) return;

				this.closest('tr').classList.add('selected-row');

				const instance = M.Sidenav.getInstance(
					document.getElementById(targetId)
				);
				instance.open();
			});
		});
	}

	closeSidenav(event) {
		const instance = M.Sidenav.getInstance(event.target.closest('.sidenav'));
		instance.close();
	}
}
