import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    this.initializeTableSort();
  }

  initializeTableSort() {
    let ph = 0;

    $(".annotation-sortable").sortable({
      axis: "y",
      items: ".sortable-item",
      cursor: "move",
      start: function (event, ui) {
        ui.placeholder.height(ph);
      },
      helper: function (event, ui) {
        if (ui.hasClass("show-over-row")) {
          let id = ui.attr("id");
          $("#" + id).addClass("ui-selected");
          $("#" + id + "-over").addClass("ui-selected");

          let selected = ui.parent().children(".ui-selected").clone();
          ph = ui.outerHeight() * selected.length;

          ui.data("multidrag", selected).siblings(".ui-selected").remove();

          return $("<tr/>").append(selected);
        }

        ui.children().each(function () {
          $(this).width($(this).width());
        });

        return ui;
      },
      update: function (event, ui) {
        let item = ui.item;
        let item_data = item.data();
        let params = {
          _method: "put",
          authenticity_token: $('meta[name="csrf-token"]').attr("content"),
        };

        params[item_data.modelName] = {
          row_order_position: item.index() - item_data.offset,
        };

        $.ajax({
          type: "POST",
          url: item_data.updateUrl,
          dataType: "html",
          data: params,
        }).then((res) => {
          // ソート後の項目に置換;
          $(".service-application-form-annotation").remove();
          $("#service_application_form_annotations").append(res);
        });
      },
      stop: function (event, ui) {
        let selected = ui.item.data("multidrag");

        if (selected) {
          ui.item.after(selected);
          ui.item.remove();
          selected.removeClass("ui-selected");
          selected.children("td").removeClass("ui-selected");
        }
      },
    });
  }
}
